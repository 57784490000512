<template>
  <v-app>
    <Sidebar/>

    <v-flex class="alltheitens ">
      <v-row>
        <v-card width="100%" style="background-color: #120222; color: #FFF">
          <v-card-text class="text-center mx-auto justify-content">
             <h2 class="text-white"> VIZUALIZAR CORTESIA: </h2>
             <v-row>
             <v-col>
            <v-select
            class="mt-2"
            :items="eventos" item-text="name" item-value="_id" dark
            attach v-model="selectEventCortesy" @change="GetCortesy()"
             outlined
            label="Evento"                              
            ></v-select>
             </v-col>
             </v-row>
             <v-row>
              <v-flex   v-for="item in cortezy" class="justify-center mx-auto mt-2" :key="item._id">
                <v-card width="90%" class="justify-center mx-auto mt-2 bilheteria-card" dark>
                   <v-divider></v-divider>
                    <v-col class="card-text">
                        <v-row class="justify-center mx-auto text-center">
                            <v-col md="8" cols="8" class=" justify-center  text-center mx-auto">
                                <v-text-field 
                                type="text" 
                                label="Id Cortesia:"
                                outlined
                                v-model="item._id" readonly>
                                </v-text-field>
                            </v-col>
                               
                            <v-col>
                                <v-text-field 
                                label="Status:"
                                v-model="item.status" readonly>
                                </v-text-field>
                           </v-col>
                           <v-col>
                              <v-text-field 
                              label="Tipo Ingresso:"
                              
                              v-model="item.paymentId" readonly>
                              </v-text-field>
                            </v-col>
                            </v-row>
                            <v-card-actions class=" justify-center mx-auto">
                              <v-btn color="red"  @click="DeleteCortesy(item)">Deletar</v-btn>
                            </v-card-actions>
                        </v-col>
                </v-card>
                </v-flex>
             </v-row>
          </v-card-text>

        </v-card>
      </v-row>
      <v-row>
        <v-card width="100%" style="background-color: #120222; color: #FFF">
          <v-card-text class="text-center mx-auto justify-content">
             <h2 class="text-white"> VIZUALIZAR PERMUTAS: </h2>
             <v-row class="text-white text-center mx-auto">
                Evento
                <v-switch dark
                  v-model="showMessages2"
                  label=""
                >
                </v-switch>
                Restaurante
              </v-row>
                <v-col>
                    <v-select v-if="showMessages2 === false"
                    class="mt-2"
                    :items="eventos" item-text="name" item-value="_id" dark
                    attach v-model="viewPermutas" @change="GetPermutas()"
                    outlined
                    label="Evento"                              
                    ></v-select>
                    <v-select v-else
                      class="mt-2"
                      :items="restaurantes" item-text="name" item-value="_id" dark
                      attach v-model="viewPermutas" @change="GetPermutas()"
                      outlined
                      label="Restaurante"                              
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-row v-if="permutas.length >= 1" class="justify-end mx-auto text-center mb-2">
                      <v-col>
                    <v-text-field dark
                    label="Valor Recarga:" dense
                    type="number" v-model="valueRecarga">
                    </v-text-field>
                    <v-btn
                    depressed
                    color="success"
                    @click="PutPermuta()"
                    > <!-- removeEvent()-->
                      RECARREGAR PERMUTAS
                    </v-btn>
                      </v-col>
                </v-row>
                <v-flex   v-for="item in permutas" class="justify-center mx-auto mt-2" :key="item._id">
                <v-card width="90%" class="justify-center mx-auto mt-2 bilheteria-card" dark>
                   <v-divider></v-divider>
                    <v-col class="card-text">
                      <v-row  v-if="item.userCpf != null " class="justify-center mx-auto text-center"> 
                          <v-col cols="12">
                            <v-text-field 
                            label="Nome:"
                            v-model="item.userName" readonly>
                            </v-text-field>
                         </v-col>
                         <v-col cols="12">
                           <v-text-field 
                            label="CPF:"
                           v-model="item.userCpf" readonly>
                            </v-text-field>
                         </v-col>
                      </v-row>
                      <v-row class="justify-center mx-auto text-center"> 
                          <v-col>
                            <v-text-field 
                            label="Valor Total:"
                            v-model="item.value" readonly>
                            </v-text-field>
                         </v-col>
                         <v-col cols="8">
                           <v-text-field 
                            label="Código:"
                           v-model="item.exchangeId" readonly>
                            </v-text-field>
                         </v-col>
                      </v-row>
                    </v-col>
                </v-card>
                <v-row class="justify-center mx-auto text-center mb-5 mt-2">
                    <v-btn
                    depressed
                    color="error"
                    @click="DeletePermuta(item)"
                    > <!-- removeEvent()-->
                      EXCLUIR
                    </v-btn>
                </v-row>
                </v-flex>
                  </v-col>
       </v-card-text>

        </v-card>
      </v-row>
      <v-row class="mt-5 mb-10">
        <v-card width="100%" style=" background-color: #120222; border: dotted #FFF;">
          <v-card-text class="text-center mx-auto justify-content">
             <h2 class="text-white"> CRIAR PERMUTAS </h2>
             <v-row class="text-white text-center mx-auto">
                Evento
                <v-switch dark
                  v-model="showMessages"
                  label=""
                >
                </v-switch>
                Restaurante
              </v-row>
              <v-row>
               <v-row>
                <v-col>
                    <v-select v-if="showMessages === false"
                    class="mt-2"
                    :items="eventos" item-text="name" item-value="_id" dark
                    attach v-model="newPermuta.localId" 
                    outlined
                    label="Evento"                              
                    ></v-select>
                    <v-select v-else
                      class="mt-2"
                      :items="restaurantes" item-text="name" item-value="_id" dark
                      attach v-model="newPermuta.localId" 
                      outlined
                      label="Restaurante"                              
                    ></v-select>
                  </v-col>
                  <v-col>
                    <v-text-field dark class="mt-2"
                      type="number" label="Quantidade:" outlined
                      v-model="qtnPermuta" required>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field dark class="mt-2"
                      type="number" label="Valor:" outlined
                      v-model="newPermuta.value" required>
                    </v-text-field>
                  </v-col>
               </v-row>
             </v-row>
              <v-btn color="white" class="mx-auto text-center" @click="CreatePermuta()">SALVAR</v-btn>

       </v-card-text>

        </v-card>

      </v-row>
    </v-flex>
  </v-app>
</template>
<script>
import { replaceUrl } from '@/functions/helpers'
import { showError, showSucess} from '@/global'

import { app, http } from '@/server'
import Sidebar from '@/components/Sidebar/Sidebar.vue'
export default {
  name: 'Permutas',
  components: {
    Sidebar,
  },
  data(){
    return{
      eventos: [],
      valueRecarga: null,
      viewtEvent: null,
      cortezy: [],
      permutas: [],
      selectCreate: null,
      showMessages: false,
      showMessages2: false,
      selectEvent: null,
      selectShop: null,
      selectEventCortesy: null,
      selectEventCreate: null,
      selectShopCreate: null,
      criarestaurante: false,
      loadingRestaurantes: false,
      modal: false,
      modal12: false,
      viewPermutas: null,
      restaurantes: null,
      qtnPermuta: null,
      restaurantes: null,
      eventosEbares: null,
      newPermuta: {
        localId: null,
        value: null,
      }
    }
  },
  mounted() {
    document.title = 'Permutas'
    this.GetEventos()
  },
  methods:{
    GetEventos() {
      http.request(app.getEventos)
      .then(res => {
        this.eventos = res.data
        this.GetBares()

      })
      .catch((err)=>{
        showError()
      })
    },
    GetBares(){
      http.request(app.getRestaurante)
      .then(res => {
          this.restaurantes = res.data
      })
      .catch()
      .catch((err)=>{
        console.log(err)
      })
    },
    GetCortesy(){
      this.cortezy = []
      console.log(this.selectEventCortesy)
      const url = app.getCortesia.url
      app.getCortesia.url = replaceUrl(url, '{event_id}', this.selectEventCortesy)
      http.request(app.getCortesia)
      .then(res => {
          this.cortezy = res.data
          console.log(res.data)
          app.getCortesia.url = '/promoter/cortesy/{event_id}'
      })
      .catch()
      .catch((err)=>{
        app.getCortesia.url = '/promoter/cortesy/{event_id}'
      })
    },
    DeleteCortesy(item){
      const url = app.deleteCortesia.url
      app.deleteCortesia.url = replaceUrl(url, '{cortesy_id}', item._id)

      http.request(app.deleteCortesia)
      .then(res => {
        if(res.status == 200){
          app.deleteCortesia.url = '/promoter/cortesy/{cortesy_id}'
          showSucess(res)
        }
      })
      .catch((err)=>{
        if (err != 200){    
          showError(err)
        }
      })

    },
    GetPermutas(){
      const url = app.getPermuta.url
      app.getPermuta.url = replaceUrl(url, '{event_id}', this.viewPermutas)

      http.request(app.getPermuta)
      .then(res => {
        console.log(res)
        app.getPermuta.url = '/promoter/exchange/{event_id}'
          this.permutas = res.data
      })
      .catch((err)=>{
        app.getPermuta.url = '/promoter/exchange/{event_id}'
        this.permutas= null
        console.log(err)
      })
    },
    CreatePermuta(){
      console.log(this.newPermuta)
      const url = app.createPermuta.url
      app.createPermuta.url = replaceUrl(url, '{query}', this.qtnPermuta)
      app.createPermuta.data = this.newPermuta
      http.request(app.createPermuta)
      .then(res => {
          app.createPermuta.url = '/promoter/exchange?qnt={query}'
          showSucess(res)

      })
      .catch((err)=>{
        app.createPermuta.url = '/promoter/exchange?qnt={query}'
        showError(err)
      })
    },
    PutPermuta(){
      const url = app.recargaPermuta.url
      const IdUrl = replaceUrl(url, '{event_id}', this.viewPermutas)
      app.recargaPermuta.url = replaceUrl(IdUrl, '{qtn_recarga}', this.valueRecarga)
      http.request(app.recargaPermuta)
      .then(res => {
        if(res.status == 200){
          app.recargaPermuta.url = '/promoter/exchange/{event_id}?value={qtn_recarga}'
          showSucess(res)
          this.GetPermutas()
        }
      })
      .catch((err)=>{
        if (err != 200){    
          app.recargaPermuta.url = '/promoter/exchange/{event_id}?value={qtn_recarga}'
          showError(err)
        }
      })
    },
    DeletePermuta(item){
      console.log(item)
      const url = app.deletePermuta.url
      app.deletePermuta.url = replaceUrl(url, '{event_id}', item.exchangeId)

      http.request(app.deletePermuta)
      .then(res => {
        if(res.status == 200){
          app.deletePermuta.url = '/promoter/exchange/{event_id}'
          showSucess(res)
        }
      })
      .catch((err)=>{
        if (err != 200){    
          app.deletePermuta.url = '/promoter/exchange/{event_id}'
          showError(err)
        }
      })

    },
  }
  

}
</script>
